/*** CSS overides from FE lib **/

body {
    color: #000000 !important;
    line-height: normal !important;
}

.vf-button-wrapper {
    &:last-of-type {
        margin-bottom: 0px !important;
    }
}

.vf-title-wrapper {
    margin: 0 !important;
}

.vf-input-wrapper .vf-input {
    background-color: #fff;
    border: 1px solid #666;
    border-radius: 6px;
    color: #333;
    font-family: VodafoneRg-Regular, Arial, sans-serif;
    font-size: 18px;
    height: 48px;
    line-height: 28px;
    padding:0 8px 0 16px !important;
    text-align: left;
}
/** Fixed for standalone label, which has position absolute
in FE-shared lib */
.vf-title-wrapper.small h1 {
    color: #333;
    font-family: VodafoneRg-Bold, Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
}

.vf-input-wrapper.label-standalone {
  margin: 0px !important;  
}


.vf-input-wrapper.label-standalone .vf-input-label {
    margin-bottom: 8px !important;
    font-family: VodafoneRg-Regular, Arial, sans-serif !important;
    position: inherit;
    top: 0 !important;
    display: inline-block;
    font-size: 18px !important;
    line-height: inherit !important;
}

.vf-input-label {
    font-size: 18px !important;
}
.vf-input-otp-wrapper .vf-input-otp {
    justify-content: left !important;
}

.confirm_pin_password {
  .vf-input-otp-wrapper  {
    margin: 0 !important;
    .vf-input-otp {
      justify-content: space-between !important;
      .vf-input-otp-part-wrap {
        width: 48px !important;
        height: 48px !important;
      }
    }
  }
  
}

/* This is a fix for password icon which loosing its postion from inside input*/
.vf-input-password-icon {
    position: relative;
}
/* the default lib link has #333 color */
.vf-link {
    color: #666 !important;
    cursor: pointer;
    text-decoration: underline;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    margin: 16px 0;
    display: inline-block;
}

.vf-link  {
    &:hover {
        color: #333333 !important;
    }
}

#with-link-wrapper span {
    margin: 0 !important
}

.input-opco-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.vf-button-wrapper .vf-button.secondary {
    background-color: #666 !important;
}

.vf-button-wrapper .vf-button.secondary:hover {
    background-color: #333 !important;
}

#notyoulink{
    color:#333 !important;
    margin: 0px !important;
    font-weight: normal !important;
}

// .user-name {
//     margin-top: 16px !important;
// }

.input_message_wrap + .section-form .box .user-name {
  margin: -16px 0 0 0 !important;
}

.vf-input-otp-wrapper .vf-input-otp-label {
    text-align: left !important;
}

@media screen and (min-width: 768px) {
    .vf-link {
        font-size: 18px;
    }
    .input-border-box > div {
        height: auto !important;
    }
}

.vf-input-wrapper .vf-input-error:before {
    background-image: url('../../assets/img/input_field.svg') !important;
}

.vf-input-wrapper .vf-input-icon {
    width: 24px !important;
    height: 24px !important;
    top: 10px !important
}

.vf-text-wrapper {
    font-family: "VodafoneLt-Regular", Arial, sans-serif !important;
    margin: 16px auto 32px !important;
    text-align: center !important;
}


.input-wrapper:last-of-type {
  margin-bottom: 0px !important;
}

//** confirm pin css fixes **//
.register-btn-wrapper {
  margin-top: 32px;
}

.validate-btn-wrapper{
  margin-top: 20px;
}

.validate-btn-wrapper-tel {
   margin-top: 48px;
}

.vf-input-otp-wrapper {
  // .vf-input-otp-part-wrap {
  //   width: 48px !important;
  //   height: 48px !important;
  // }
  // margin: 0px !important;
}
@media screen and (min-width: 768px) {
    .vf-link {
        font-size: 18px;
    }
}
