@import "../../assets/scss/variables";
@import "../../assets/scss/images";

.vf-tabs-wrapper-idp {
    display: flex;
    flex-direction: column;
    height: 100%;

    .vf-tabs {
        display: flex;
        flex-direction: column;
        -webkit-tap-highlight-color: transparent;

        .vf-tab-wrapper {
            display: flex;
            // flex: 1;
            flex-direction: column;
            justify-self: center;
            // width: 144px;
            padding: 16px;
            background-color: $vodafoneLighterGrey;
            border-bottom: 1px solid #ccc;
            box-sizing: border-box;
            cursor: pointer;

            &.selected {
                background-color: white;

                .vf-tab-title {
                    font-family: $primaryFontFamilyBold;
                    color: $vodafoneDarkGrey;
                    font-weight: bold;
                }
            }

            .vf-tab-title {
                width: auto;
                text-align: left;
                margin: auto 0;
                padding: 0;
                font-size: 14px;
                text-decoration: underline;
                color: black;

                &.icon {
                    padding-left: 24px;
                    background-repeat: no-repeat;
                    background-position: 0 4px;
                    background-size: 16px;

                    &.how_to_use {
                        background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjAiIHk9IjAiIHZpZXdCb3g9IjAgMCA2NCA2NCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlPi5zdDB7ZmlsbDojNmNjMDRhfTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTMyIDY0QzE0LjIgNjQgMCA0OS40IDAgMzIgMCAxNC4yIDE0LjYgMCAzMiAwczMyIDE0LjYgMzIgMzJjMCAxNy44LTE0LjIgMzItMzIgMzJ6bTAtNjAuNEMxNi42IDMuNiAzLjYgMTYuMiAzLjYgMzJjMCAxNS40IDEyLjYgMjguNCAyOC40IDI4LjRTNjAuNCA0Ny44IDYwLjQgMzJjMC0xNS40LTEzLTI4LjQtMjguNC0yOC40eiIvPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0yOCAyMGMwLTIuMiAxLjgtNCA0LTRzNCAxLjggNCA0LTEuOCA0LTQgNC00LTEuOC00LTR6bTggMjdWMjljMC0uNS0uNS0xLTEtMWgtNmMtLjUgMC0xIC40LTEgMXYxOGMwIC41LjQgMSAxIDFoNmMuNSAwIDEtLjUgMS0xeiIvPjwvc3ZnPg==);
                    }
    
                    &.necessary_cookies {
                        background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjAiIHk9IjAiIHZpZXdCb3g9IjAgMCA2NCA2NCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlPi5zdDB7ZmlsbDojNmNjMDRhfTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTMyIDY0QzE0LjIgNjQgMCA0OS40IDAgMzIgMCAxNC4yIDE0LjYgMCAzMiAwczMyIDE0LjYgMzIgMzJjMCAxNy44LTE0LjIgMzItMzIgMzJ6bTAtNjAuNEMxNi42IDMuNiAzLjYgMTYuMiAzLjYgMzJjMCAxNS40IDEyLjYgMjguNCAyOC40IDI4LjRTNjAuNCA0Ny44IDYwLjQgMzJjMC0xNS40LTEzLTI4LjQtMjguNC0yOC40eiIvPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0zMC40IDQ4LjNMMTYuNiAzNC44bDIuOC0yLjggMTAuMiA5LjkgMTQuNy0yNSAzLjQgMnoiLz48cGF0aCBpZD0iX3gzQ19TbGljZV94M0VfXzFfIiBmaWxsPSJub25lIiBkPSJNMCAwaDY0djY0SDB6Ii8+PC9zdmc+);
                    }
    
                    &.functional_cookies {
                        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA3XAAAN1wFCKJt4AAAAB3RJTUUH5AYWDTokqkGnLAAAAvxJREFUeNrlW1uSmzAQ7J517kU+l6usj+NchfxtcatUastMfsBFKBkkMSNkVlX+M5K656HWSCKc27Vv7wAIgKoKkknf35qOnvOjF2hVFQDJgEuTQWtLqyotQXuTQQPgAwCUAm5NBA3A03LC177VkiQwF3isxfdaKIWQnLHoYXWvzB1DRurYtLS695IVS0TKPGhh9VLAPUjgK4KPJSJmbizlarWSkEVALcAtSOAZwO+Zt5wJfA4xYqHEamqpRpJatqUlSAgZl2d2/Rg8cibLB9pmONPa+vN+csjb+32skJv6llp2cqH/WyTkW9OJSRL0AFywz/sqAaFBSse+5/J7a7qLqgbHE28AFsCMyFH3EKh55SCpSy8AAPFy/3kfWxYsoT5vTfcWGles3d+aPEuSQl4gABByje/SxBN8TBgcLb8F+n9+LK38UoDvDYOQKJLJAY4IgzmgcXwt7QWjQCByTrVSpeu1b/UZqLHcvjoLVV3tIy8H3LWaJLhZwHQ4e7wAAAYFhLtOClNjNzaeb01HT50gU9zpkOYJKckvRRSV3jcICag+so/W6v6GknjhAUJQCALQASwBMNWCnuTw4/N90EE50gN5o24VEaxdOAagxZ4l1MeFU/JTAAWcsLay++UB+8ANbC4pFprgcZNrSg6q+spV4HQC5gxORHi56Vq/3mLsWQ6RSgyhJKdt2fLnmlMuNUvfJ4CTb6Zt5gCvCoxHf/MlWjVOva6NXUsIZKu6MWcNud4nR63Zxuouu4giJeSmNZF755p0Omx0PneItoiZe3Tye7Vj8lgcEgv01a/OnHIV2Gv9pwSseMFwJvCbe8BlZ1Pp+tfP33IG8JshsPxwKl1/fL4P1779+wrgswXEWseT/CQ5hE5dawK+665w5CDuJTQv8NEExLpYwQcTZo836BFrjk9mHo8wrcamd8IxuGr3R1V/jAl7zepZoVj0HmAMIde+/cL4AHNpbY/3SvRORClttrog9p3xoQ8nj9wrVPN0tiQZVT+e9iLDe2n9B3ogLWl7jZAvAAAAAElFTkSuQmCC);
                    }
                }
            }
        }

    }

    .vf-tabs-content {
        padding: 10px 15px;
        overflow: auto;

        .vf-tabs-content-title {
            font-family: $primaryFontFamilyBold;
            margin-bottom: 5px;
        }

        .vf-tabs-content-section {

        }
    }
}

@media screen and (min-width : 768px) {
    .vf-tabs-wrapper-idp {
        flex-direction: row;
        
        .vf-tabs {
            .vf-tab-wrapper {
                width: 154px;

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}

@media screen and (min-width : 1024px) {
}