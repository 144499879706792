@import "../../assets/scss/variables";
@import "../../assets/scss/images";
@import "../../assets/scss/animation";

// Local variables for component:
$background-color: #eeeeef;

.vf-popup-wrapper-idp {

    &.manage_cookies {
        
        .vf-popup {
            max-height: 100vh;

            .vf-popup-header-wrapper {
                padding: 0 !important;
                background-color: $background-color;
                border-bottom: 1px solid #ccc;

                .vf-popup-header {
                    border: none;
                    display: flex;
                    flex-direction: column;
                    font-family: VodafoneRg-Regular, Arial, sans-serif !important;
                    font-size: 16px !important;
                    font-weight: 700 !important;
                    height: 50px;
                    justify-content: center;
                    line-height: 78px !important;
                    padding: 0 0 0 59px !important;
                    position: relative;
                    text-align: left !important;

                    .vf-popup-header-title{
                        width:100%;
                        height:100%;
                        display:flex;
                        align-items: center;
                        text-wrap: wrap;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        display: block;
                        width: 60px;
                        height: 100%;
                        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNNDggMjRjMCAxMy4yNTUtMTAuNzQ1IDI0LTI0IDI0UzAgMzcuMjU1IDAgMjQgMTAuNzQ1IDAgMjQgMHMyNCAxMC43NDUgMjQgMjQiIGZpbGw9IiNFNjAwMDAiLz48cGF0aCBkPSJNMjQuMTkgMzcuMzgzYy02LjU5NC4wMjItMTMuNDU0LTUuNjA2LTEzLjQ4NC0xNC42NDMtLjAyLTUuOTc3IDMuMjA0LTExLjczIDcuMzI1LTE1LjE0MiA0LjAyLTMuMzI5IDkuNTI4LTUuNDY1IDE0LjUyMi01LjQ4Mi42NDMtLjAwMiAxLjMxNi4wNTIgMS43MjcuMTkxLTQuMzY3LjkwNi03Ljg0MiA0Ljk3LTcuODI3IDkuNTgxIDAgLjE1My4wMTQuMzE1LjAzLjM5MSA3LjMwNyAxLjc4IDEwLjYyNCA2LjE5IDEwLjY0NSAxMi4yOS4wMiA2LjEwMi00Ljc5NyAxMi43ODctMTIuOTM4IDEyLjgxNCIgZmlsbD0iI0ZGRiIvPjwvZz48L3N2Zz4=");
                        background-size: 32px;
                        background-position: center;
                        background-repeat: no-repeat;
                    }

                    .close {
                        right: 20px !important;
                        top: 20px;
                    }
                }
            }

            .vf-popup-content-wrapper {
                padding: 0 !important;

                .vf-popup-content {
                    font-size: 16px !important;

                    .vf-tabs-content {
                        position: relative;

                        .status {
                            font-family: $primaryFontFamily;
                            position: absolute;
                            top: 10px;
                            right: 15px;
                            line-height: 24px;

                            .success {
                                color: #090;
                            }
                        }
                    }
                }
            }

            .vf-popup-footer-wrapper {
                padding: 15px !important;
                background-color: $background-color;
                margin-top: auto;

                .vf-popup-footer {
                    color: #333;

                    .footer-links {
                        align-items: flex-start;
                        .link-wrapper{
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }

                    .button {
                        &.primary {
                                margin-top: 0;
                                min-width: 112px;
                                .button_label {
                                    font-size: 16px;
                                }
                            }
                        }
                }

                .vf-tabs-footer{
                    .link-wrap {
                        margin: 4px 0px;
                    }
                }
            }
        }
    }
}


// Manage Cookie Tabs style

.vf-tabs-wrapper-idp {
    display: flex;
    flex-direction: column;
    height: 100%;

    .vf-tabs {
        display: flex;
        flex-direction: column;
        -webkit-tap-highlight-color: transparent;

        .vf-tab-wrapper {
            display: flex;
            // flex: 1;
            flex-direction: column;
            justify-self: center;
            // width: 144px;
            height: 40px;
            padding: 10px 15px;
            background-color: $background-color;
            border-bottom: 1px solid #ccc;
            box-sizing: border-box;
            cursor: pointer;

            &.selected {
                background-color: white;

                .vf-tab-title {
                    color: #000;
                    font-weight: bold;
                }
            }

            .vf-tab-title {
                margin: auto 0;
                font-size: 14px;
                line-height: 18px;
                text-decoration: underline;
                color: #000;

                &.icon {
                    background-position: 0 0;
                    background-repeat: no-repeat;
                    background-size: 16px;
                    min-height: 16px;
                    padding-left: 24px;

                    &.how_to_use {
                        background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjAiIHk9IjAiIHZpZXdCb3g9IjAgMCA2NCA2NCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlPi5zdDB7ZmlsbDojNmNjMDRhfTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTMyIDY0QzE0LjIgNjQgMCA0OS40IDAgMzIgMCAxNC4yIDE0LjYgMCAzMiAwczMyIDE0LjYgMzIgMzJjMCAxNy44LTE0LjIgMzItMzIgMzJ6bTAtNjAuNEMxNi42IDMuNiAzLjYgMTYuMiAzLjYgMzJjMCAxNS40IDEyLjYgMjguNCAyOC40IDI4LjRTNjAuNCA0Ny44IDYwLjQgMzJjMC0xNS40LTEzLTI4LjQtMjguNC0yOC40eiIvPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0yOCAyMGMwLTIuMiAxLjgtNCA0LTRzNCAxLjggNCA0LTEuOCA0LTQgNC00LTEuOC00LTR6bTggMjdWMjljMC0uNS0uNS0xLTEtMWgtNmMtLjUgMC0xIC40LTEgMXYxOGMwIC41LjQgMSAxIDFoNmMuNSAwIDEtLjUgMS0xeiIvPjwvc3ZnPg==);
                    }

                    &.necessary_cookies {
                        background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjAiIHk9IjAiIHZpZXdCb3g9IjAgMCA2NCA2NCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlPi5zdDB7ZmlsbDojNmNjMDRhfTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTMyIDY0QzE0LjIgNjQgMCA0OS40IDAgMzIgMCAxNC4yIDE0LjYgMCAzMiAwczMyIDE0LjYgMzIgMzJjMCAxNy44LTE0LjIgMzItMzIgMzJ6bTAtNjAuNEMxNi42IDMuNiAzLjYgMTYuMiAzLjYgMzJjMCAxNS40IDEyLjYgMjguNCAyOC40IDI4LjRTNjAuNCA0Ny44IDYwLjQgMzJjMC0xNS40LTEzLTI4LjQtMjguNC0yOC40eiIvPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0zMC40IDQ4LjNMMTYuNiAzNC44bDIuOC0yLjggMTAuMiA5LjkgMTQuNy0yNSAzLjQgMnoiLz48cGF0aCBpZD0iX3gzQ19TbGljZV94M0VfXzFfIiBmaWxsPSJub25lIiBkPSJNMCAwaDY0djY0SDB6Ii8+PC9zdmc+);
                    }

                    &.cookie_icon {
                        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA3XAAAN1wFCKJt4AAAAB3RJTUUH5AYWDTokqkGnLAAAAvxJREFUeNrlW1uSmzAQ7J517kU+l6usj+NchfxtcatUastMfsBFKBkkMSNkVlX+M5K656HWSCKc27Vv7wAIgKoKkknf35qOnvOjF2hVFQDJgEuTQWtLqyotQXuTQQPgAwCUAm5NBA3A03LC177VkiQwF3isxfdaKIWQnLHoYXWvzB1DRurYtLS695IVS0TKPGhh9VLAPUjgK4KPJSJmbizlarWSkEVALcAtSOAZwO+Zt5wJfA4xYqHEamqpRpJatqUlSAgZl2d2/Rg8cibLB9pmONPa+vN+csjb+32skJv6llp2cqH/WyTkW9OJSRL0AFywz/sqAaFBSse+5/J7a7qLqgbHE28AFsCMyFH3EKh55SCpSy8AAPFy/3kfWxYsoT5vTfcWGles3d+aPEuSQl4gABByje/SxBN8TBgcLb8F+n9+LK38UoDvDYOQKJLJAY4IgzmgcXwt7QWjQCByTrVSpeu1b/UZqLHcvjoLVV3tIy8H3LWaJLhZwHQ4e7wAAAYFhLtOClNjNzaeb01HT50gU9zpkOYJKckvRRSV3jcICag+so/W6v6GknjhAUJQCALQASwBMNWCnuTw4/N90EE50gN5o24VEaxdOAagxZ4l1MeFU/JTAAWcsLay++UB+8ANbC4pFprgcZNrSg6q+spV4HQC5gxORHi56Vq/3mLsWQ6RSgyhJKdt2fLnmlMuNUvfJ4CTb6Zt5gCvCoxHf/MlWjVOva6NXUsIZKu6MWcNud4nR63Zxuouu4giJeSmNZF755p0Omx0PneItoiZe3Tye7Vj8lgcEgv01a/OnHIV2Gv9pwSseMFwJvCbe8BlZ1Pp+tfP33IG8JshsPxwKl1/fL4P1779+wrgswXEWseT/CQ5hE5dawK+665w5CDuJTQv8NEExLpYwQcTZo836BFrjk9mHo8wrcamd8IxuGr3R1V/jAl7zepZoVj0HmAMIde+/cL4AHNpbY/3SvRORClttrog9p3xoQ8nj9wrVPN0tiQZVT+e9iLDe2n9B3ogLWl7jZAvAAAAAElFTkSuQmCC);
                    }
                }
            }
        }

    }

    .vf-tabs-content {
        padding: 15px;
        overflow: auto;

        .vf-tabs-content-title {
            font-family: $primaryFontFamilyBold;
            margin-bottom: 10px;
        }

        .vf-tabs-content-section{
            hr {
                margin: 8px 0;
                width: 100%;
                min-width: 100%;
            }

            p:not(:first-child) {
                margin-top: 1em;
            }
        }
    }
}

@media screen and (min-width : 768px) {
    .vf-popup-wrapper-idp {
        &.manage_cookies {
            .vf-popup {
                max-width: 600px !important;
                height: fit-content;

                .vf-popup-header-wrapper {
                    .vf-popup-header{
                        height: 78px;
                        padding: 0 60px 0 175px !important;

                        &::before {
                            width: 160px;
                            background-size: 48px;
                        }
                    }
                }

                .vf-popup-content-wrapper {
                    .vf-popup-content {
                        .vf-tabs-wrapper-idp {
                            flex-direction: row;
                            .vf-tabs{
                                .vf-tab-wrapper {
                                    width:160px;
                                    height:90px;

                                    &:last-child {
                                        border-bottom: none;
                                    }
                                }
                            }

                            .vf-tabs-content {
                                width: 100%;
                            }
                        }
                    }
                }

                .vf-popup-footer-wrapper{
                    .vf-popup-footer {
                        .form-button-wrap{
                            min-width: 112px;
                        }

                        .vf-tabs-footer{
                            .link-wrap {
                                margin: 4px 0px;
                            }
                        }
                    }
                }
            }
        }
    }
}