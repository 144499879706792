@import "../../assets/scss/variables";
@import "../../assets/scss/images";

.vf-accordion-item-wrapper-idp {
    border-top: 1px solid #ccc;

    &:last-child {
        border-bottom: 1px solid #ccc;
    }

    &.close {
        .vf-accordion-item-content-wrapper-idp {
            max-height: 0;
        }
    }

    &.open {
        .vf-accordion-item-title-idp {
            &::before {
                transform: rotate(-180deg);
            }
        }

        .vf-accordion-item-content-wrapper-idp {
            transition: max-height 0.5s ease-out;
        }
    }

    .vf-accordion-item-title-idp {
        position: relative;
        font-weight: 700;
        padding: 20px 30px;
        cursor: pointer;

        &::before {
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE3IDEgOSA5IDEgMSIgc3Ryb2tlPSIjRTYwMDAwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=);
            background-position: 50%;
            background-repeat: no-repeat;
            bottom: 0;
            content: "";
            display: block;
            height: 20px;
            margin: auto;
            position: absolute;
            right: 10px;
            top: 0;
            transition: transform 0.25s;
            width: 20px;
        }
    }

    .vf-accordion-item-content-wrapper-idp {
        overflow: hidden;
        box-sizing: border-box;

        transition: max-height 0.25s ease-out;

        .vf-accordion-item-content-idp {
            padding: 0 30px 20px;
        }
    }
}
