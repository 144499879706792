@import "../../assets/scss/variables";
@import "../../assets/scss/images";
@import "../../assets/scss/animation";
.idp-wrapper {
    .vf-popup-overlay-idp {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9999;

        animation: fadeIn 0.01s;

        .vf-popup-wrapper-idp {
            .vf-popup {
                display: flex;
                flex-direction: column;
                position: fixed;
                top: 20px;
                bottom: 20px;
                left: 20px;
                right: 20px;
                margin: auto;
                background-color: #fff;
                box-sizing: border-box;

                animation: slideOpen 0.1s;

                .vf-popup-header-wrapper {
                    padding: 0 20px;
                    .vf-popup-header {
                        font-family: $primaryFontFamilyLT;
                        border-bottom: 1px solid #ccc;
                        font-size: 28px;
                        line-height: 28px;
                        padding: 50px 0 30px;
                        position: relative;
                        text-align: center;
                        .close {
                            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgMTkgMTkgMU0xOSAxOSAxIDEiIHN0cm9rZT0iIzMzMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+);
                            background-size: 18px;
                            cursor: pointer;
                            height: 18px;
                            position: absolute;
                            right: 0;
                            top: 20px;
                            width: 18px;
                        }
                    }
                }

                .vf-popup-content-wrapper {
                    flex: 1;
                    height: 100%;
                    padding: 0 20px;
                    overflow: auto;

                    .vf-popup-content {
                        height: 100%;
                        overflow: auto;

                        font-family: $primaryFontFamilyLT;
                        font-size: 18px;
                        line-height: 1.25 !important;

                        /* Hide scrollbar for IE, Edge and Firefox */
                        -ms-overflow-style: none; /* IE and Edge */
                        scrollbar-width: none; /* Firefox */

                        /* Hide scrollbar for Chrome, Safari and Opera */
                        &::-webkit-scrollbar {
                            display: none;
                        }

                        .vf-text-wrapper {
                            max-width: 100%;
                        }

                        h2 {
                            font-family: $primaryFontFamilyBold;
                            font-size: 18px;
                        }

                        li,
                        p {
                            h2,
                            h3 {
                                margin: 10px 0;
                            }
                        }
                    }
                }

                .vf-popup-footer-wrapper {
                    padding: 8px;
                    .footer-links {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        span {
                            padding: 0;
                            margin: 0;
                        }
                    }
                    .vf-popup-footer {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        color: $vodafoneLightGrey;

                        .button {
                            width: auto;
                        }
                    }
                }
            }
        }
    }

    @media screen and (min-width: 768px) {
        .vf-popup-overlay-idp {
            .vf-popup-wrapper-idp {
                .vf-popup {
                    top: 50px;
                    bottom: 50px;
                    max-width: 85%;

                    .vf-popup-header-wrapper {
                        padding-left: 120px;
                        padding-right: 120px;

                        .vf-popup-header {
                            .close {
                                right: -100px;
                            }
                        }
                    }
                    .vf-popup-content-wrapper {
                        padding-left: 120px;
                        padding-right: 120px;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1024px) {
    }
}
